import React from 'react'
import { SpringReverse } from 'react-burgers'
 
export default class ToggleDrawer extends React.Component {

  handleClick = event => {
    event.preventDefault();
    this.props.onClick();
  }
 
  render() {
    return (
      <SpringReverse
        onClick={this.handleClick}
        active={this.props.expanded}
        lineHeight={2}
        width={30}
        lineSpacing={8}
        padding='0px'
        color='#ffffff'
      />
    );
  }
}