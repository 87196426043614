import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import header from "../../styles/layout/header.module.sass"
import NavToggleBtn from "./nav-drawer-toggle-button"
import NavDrawer from "../../components/header/nav-drawer"
import SmoothCollapse from "react-smooth-collapse"
import cx from "classnames"

export default ({ menuLinks }) => {
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (expanded) {
        setExpanded(false)
      }
    })
  })

  return (
    <div id="main" className={cx("main", header.nav)}>
      <header className={header.container} >
        <div className={header.format}>
            <div className={header.title} >
                <Link
                to="/"
                style={{
                    color: "white",
                    textDecoration: "none",
                }}
                >
                {"jake-t.codes"}
                </Link>
            </div>
            <div className={header.nav}>
                <nav>
                    <ul className={header.navContainer}>
                        {menuLinks.map(link => (
                        <li
                            key={link.name}
                            className={header.navItem}
                        >
                          <Link className={header.navLink} to={link.link} activeClassName={header.navLinkActive}>
                          {link.name}
                          </Link>
                        </li>
                        ))}
                    </ul>
                </nav>
            </div>
            <div className={header.toggleBtn}>
              <NavToggleBtn onClick={() => setExpanded(!expanded)} expanded={expanded} />
            </div>
        </div>
        <SmoothCollapse expanded={expanded}>
          <NavDrawer menuLinks={menuLinks} expanded={expanded}/>
        </SmoothCollapse>
      </header>
    </div>
  )
}