import React from "react"
import footer from "../styles/layout/footer.module.sass"
import github from "../images/github.svg"
import linkedin from "../images/linkedin.svg"
// import twitter from "../images/twitter.svg"
import cx from "classnames"

const Footer = props => (
    <footer className={cx("pt-4", footer.container)}>
        <ul className={footer.socialsContainer}>
            <li className={footer.socialsItem}>
                <a href="https://www.github.com/jakethomson">
                    <img src={github} alt="Github logo"/>
                </a>
            </li>
            <li className={footer.socialsItem}>
                <a href="https://www.linkedin.com/in/jake-thomson">
                    <img src={linkedin} alt="LinkedIn logo"/>
                </a>
            </li>
            {/* <li className={footer.socialsItem}>
                <a href="https://www.twitter.com/its_jaaake">
                    <img src={twitter} alt="Twitter logo"/>
                </a>
            </li> */}
        </ul>
        <div className={footer.signature}>
            Made with ❤ by<br/>
            Jake Thomson <i style={{fontSize: "13px"}}>(without the 'p')</i>
        </div>
    </footer>
)

export default Footer;