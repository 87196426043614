import React from "react"
import { Helmet } from "react-helmet"
import Header from "./header/header"
import { StaticQuery, graphql } from "gatsby"
import Footer from "../components/footer"

export default ({ children }) => (

    <StaticQuery
        query={graphql`
        query SiteTitleQuery {
            site {
            siteMetadata {
                title
                menuLinks {
                name
                link
                }
            }
            }
        }
        `}
        render={data => (
        <React.Fragment>
            <Helmet
            title={'Home'}
            meta={[
                { name: 'description', content: 'Sample' },
                { name: 'keywords', content: 'sample, something' },
            ]}
            >
            </Helmet>
            <Header menuLinks={data.site.siteMetadata.menuLinks} siteTitle={data.site.siteMetadata.title} />
            <div
            style={{
                margin: '0 auto',
                maxWidth: 1050,
                minHeight: 'calc(100vh - 142px - 86px)',
                padding: '1.45rem 0.5rem'
            }}
            >
            {children}
            </div>
            <Footer />
        </React.Fragment>
        )}
    />
)