import React from "react"
import { Link } from "gatsby"

import header from "../../styles/layout/header.module.sass"

const NavDrawer = props => (
    <nav className={header.navDrawer} >
        <ul className={header.navDrawerContainer}>
            {props.menuLinks.map(link => (
            <li
                key={link.name}
                className={header.navDrawerItem}
            >
                <Link className={header.navDrawerLink} to={link.link} activeClassName={header.navDrawerLinkActive}>
                    {link.name}
                </Link>
            </li>
            ))}
        </ul>
    </nav>
);

export default NavDrawer;